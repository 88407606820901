const { tablet } = require('../../scss/_variables.scss').default;

const $ = require('jquery');


const matchMediaMobile = window.matchMedia(`(max-width: ${tablet - 1}px)`);

class TabbedContent {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$listNode = this.$node.find('[data-ref~="list"]');
        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');
        this.$primaryContentNodes = this.$node.find('[data-ref~="primary-content"]');
        this.$secondaryContentNodes = this.$node.find('[data-ref~="secondary-content"]');

        this.$triggerNodes.on('click', this.handleTriggerClick.bind(this));
        this.scrollActiveTriggerIntoView();
    }

    handleTriggerClick (event) {
        const $triggerNode = $(event.currentTarget);
        const i = this.$triggerNodes.index($triggerNode);

        const $primaryContentNode = this.$primaryContentNodes.eq(i);
        const $secondaryContentNode = this.$secondaryContentNodes.eq(i);

        this.$triggerNodes.removeClass('is-active');
        $triggerNode.addClass('is-active');

        this.$primaryContentNodes.removeClass('is-active');
        $primaryContentNode.addClass('is-active');

        this.$secondaryContentNodes.removeClass('is-active');
        $secondaryContentNode.addClass('is-active');

        this.scrollActiveTriggerIntoView();
    }

    scrollActiveTriggerIntoView() {
        if (matchMediaMobile.matches) {
            const listNode = this.$listNode.get(0);
            const triggerNode = this.$triggerNodes.filter('.is-active').first().get(0);
            const left = triggerNode.getBoundingClientRect().left
                + listNode.getBoundingClientRect().left
                + listNode.scrollLeft
                - parseInt(window.getComputedStyle(listNode).getPropertyValue('padding-left'), 10);
            if ('scrollTo' in listNode) {
                listNode.scrollTo({
                    top: 0,
                    left,
                    behavior: 'smooth',
                });
            } else {
                listNode.scrollLeft = left;
            }
        }
    }
}

module.exports = TabbedContent;
