const $ = require('jquery');

const components = {
    // Define components
    'ajax-nav': require('./components/ajax-nav'),
    'ajax-subnav': require('./components/ajax-subnav'),
    'background-gallery': require('./components/background-gallery'),
    'card-carousel': require('./components/card-carousel'),
    'header': require('./components/header'),
    'newsletter-form': require('./components/newsletter-form'),
    'person-card': require('./components/person-card'),
    'simple-subnav': require('./components/simple-subnav'),
    'scrolling-nav': require('./components/scrolling-nav'),
    'tabbed-content': require('./components/tabbed-content'),
    'testimonial-carousel': require('./components/testimonial-carousel'),
    'theme-card': require('./components/theme-card'),
    'theme-carousel': require('./components/theme-carousel'),
};

const initComponents = (components, rootNode = document.body) => {
    // Find any tagged components on the page (or within optional `rootNode`)
    $(rootNode).find('[data-component]').each((i, node) => {
        $(node).data('component').split(/\s+/).forEach((componentName) => {
            const component = components[componentName];
            if (component) new component(node);
        });
    });
};

$(() => {
    // Initialize components
    initComponents(components);

    // Listen for custom `rebind` event, which can be triggered on any element
    // to re-initialize components within it
    $(document).on('rebind', (event) => {
        initComponents(components, event.target);
    });
});
